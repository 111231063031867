<template>
  <div class="tw-flex tw-justify-center">
    <div class="sculptor">
      <div class="top-section tw-flex tw-items-center">
        <p>RIBS</p>
      </div>
      <div class="tw-flex card-sculptors">
        <div class="total-unit">
          <div class="tw-flex tw-items-center">
            <icons name="cubes" color="#B51ECD" class="mr-2" />
            <span class="darker-text ml-1">Users</span>
            <v-spacer></v-spacer>
            <div class="tw-flex">
              <span class="lighter-text">Today</span>
              <icons name="light-arrow" class="ml-1" style="margin-top:2px" />
            </div>
          </div>
          <p class="total-unit-figure mt-3">{{this.productUsers.length}}</p>
          <div class="tw-flex tw-justify-end">
            <icons name="temp-graph" color="#B51ECD" />
          </div>
        </div>
        <div class="total-unit">
          <div class="tw-flex tw-items-center">
            <icons name="cubes" color="#804CC6" class="mr-2" />
            <span class="darker-text ml-1">Bookings</span>
            <v-spacer></v-spacer>
            <div class="tw-flex">
              <span class="lighter-text">Today</span>
              <icons name="light-arrow" class="ml-1" style="margin-top:2px" />
            </div>
          </div>
          <p class="total-unit-figure mt-3">50000</p>
          <div class="tw-flex tw-justify-end">
            <icons name="temp-graph" color="#804CC6" />
          </div>
        </div>
        <div class="categories-box">
          <div class="tw-flex tw-items-center">
            <span class="lighter-text">Complaints</span>
            <v-spacer></v-spacer>
            <icons name="light-arrow" class="ml-1" style="margin-top:2px" />
          </div>
          <div class="tw-flex inner-sculptor">
            <v-col cols="6" sm="6" style="padding:5px">
              <p class="total-unit-figure mt-1 small-darker-text">100</p>
              <span class="status-tags" style="color:#0F674E">Active</span>
            </v-col>
            <v-col cols="6" sm="6" style="padding:5px">
              <p class="total-unit-figure mt-1 small-darker-text">10</p>
              <span class="status-tags" style="color:#71011C ">Resolved</span>
            </v-col>
          </div>
        </div>
      </div>
      <div class="company-dashboard lg:tw-mt-10 tw-mt-5 tw-p-5">
        <div class="tw-flex tw-justify-between tw-w-full tw-items-center tw-flex-wrap">
          <div class="mr-2 tw-mt-5 lg:tw-mt-0 laptop-view">
            <v-btn text class="header" @click="selectedFilter = 'users'"
              :color="selectedFilter === 'users' ? '#263238' : '#939393'">
              <icons :color="selectedFilter === 'users' ? '#B51ECD' : '#939393'" class="mr-2" name="cubes" />
              All Users
            </v-btn>
            <v-btn text class="header" @click="selectedFilter = 'bookings'"
              :color="selectedFilter === 'bookings' ? '#263238' : '#939393'">
              <icons :color="selectedFilter === 'bookings' ? '#804CC6' : '#939393'" class="mr-2" name="cubes" />
              All Bookings
            </v-btn>
          </div>
          <div class="mobile-view">
            <v-select :items="items" item-text="text" hide-details class="darker-text tf-styles" item-value="value" flat
              solo v-model="selectedFilter">
              <template #append>
                <icons name="select-dropdown" />
              </template>
            </v-select>
          </div>
          <div class="mr-2 search-div">
            <v-text-field placeholder="Search" color="#004aad" class="p-0 tf-styles" solo flat dense hide-details
              v-model="search" style="border-radius: 10px; border: 1px solid rgba(79, 79, 79, 0.08)">
              <template #prepend-inner>
                <icons class="mt-1 mr-1" name="search-icon" />
              </template>
            </v-text-field>
          </div>
          <v-data-table v-if="selectedFilter === 'users'" :headers="userHeaders" :mobile-breakpoint="0" :search="search"
            item-key="name" :single-select="false" height="available" :hide-default-footer="productUsers.length <= 7"
            :items="filteredPaymentLogs" @click:row="$router.push({name:'ViewUser'})" :no-data-text="not_found"
            class="mt-4 mx-1 table" :loading="loading">
            <template #header.profile>
              <span class="status">Profile</span>
            </template>

            <template v-slot:[`item.profile`]="{ item }">
              <div class="circle">
                <span class="td-style">{{ getFirstLetter(item.productUserName) }}</span>
              </div>
            </template>

            <template #header.productUserName>
              <span class="status">Name</span>
            </template>

            <template v-slot:[`item.productUserName`]="{ item }">
              <span class="td-style">{{ item.productUserName }}</span>
            </template>

            <template #header.category>
              <span class="status">Category</span>
            </template>

            <template v-slot:[`item.category`]="{ item }">
              <span class="td-style text-uppercase" >{{ item.category }}</span>
            </template>
            <template #header.status>
              <span class="status">Status</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip v-if="item.status === true" color="#00C48C69">
                <span class="td-style" style="color: #0F674E">
                  Active
                </span>
              </v-chip>
              <v-chip v-if="item.status === false" color="#C8486759">
                <span class="td-style" style="color: #71011C">
                  Inactive
                </span>
              </v-chip>
            </template>
            <template #header.subscription>
              <span class="status">Subscription</span>
            </template>

            <template v-slot:[`item.subscription`]="{ item }">
              <span class="td-style">{{ item.subscription }}</span>
            </template>
            <template #header.verified>
              <span class="status">Verification</span>
            </template>
            <template v-slot:[`item.verified`]="{ item }">
              <v-chip v-if="item.verified === true" color="#0075CA80">
                <span class="td-style tw-flex" style="color: #163043">
                  <icons name="tick" class="mr-1" />
                  Verified
                </span>
              </v-chip>
              <v-chip v-if="item.verified === false" color="#D3766A33">
                <span class="td-style" style="color: #D3766A">
                  Not verified
                </span>
              </v-chip>
            </template>
            <template #header.startDate>
              <span class="status">Start Date</span>
            </template>

            <template v-slot:[`item.startDate`]="{ item }">
              <span class="td-style">{{ item.startDate }}</span>
            </template>
            <template #header.actions>
              <span class="status">Actions</span>
            </template>
            <template v-slot:[`item.actions`]="{item}">
              <v-menu bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <td class="d-flex justify-end">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon :color="color">mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </td>
                </template>
                <v-list>
                  <v-list-item class="" @click="view(item)">
                    <v-list-item-title style="color: black" class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                      <icons name="view-paddler" class="mr-2" />
                      View User
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item class="">
                    <v-list-item-title style="color: black" class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                      <icons name="tools" class="mr-2" />
                      Update User
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
          <v-data-table v-else :headers="bookingHeaders" :mobile-breakpoint="0" :search="search" item-key="name"
            :single-select="false" height="available" :items="filteredPaymentLogs" :no-data-text="not_found" class="mt-4 mx-1 table" :loading="loading">
            <template #header.traveller>
              <span class="status"> Full Name</span>
            </template>

            <template v-slot:[`item.traveller`]="{ item }">
              <span class="td-style">{{ item.traveller }}</span>
            </template>

            <template #header.tc>
              <span class="status">Company</span>
            </template>
            <template v-slot:[`item.tc`]="{ item }">
              <span class="td-style">{{ item.tc }}</span>
            </template>
            <template #header.bookingId>
              <span class="status">Booking ID</span>
            </template>
            <template v-slot:[`item.bookingId`]="{ item }">
              <span class="td-style">{{ item.bookingId }}</span>
            </template>
            <template #header.departure>
              <span class="status">departure</span>
            </template>
            <template v-slot:[`item.departure`]="{ item }">
              <span class="td-style">{{ item.departure }}</span>
            </template>
            <template #header.arrival>
              <span class="status">arrival</span>
            </template>
            <template v-slot:[`item.arrival`]="{ item }">
              <span class="td-style">{{ item.arrival }}</span>
            </template>
            <template #header.paymentId>
              <span class="status">Payment ID</span>
            </template>
            <template v-slot:[`item.paymentId`]="{ item }">
              <span class="td-style">{{ item.paymentId }}</span>
            </template>
            <template #header.tripCost>
              <span class="status">Price</span>
            </template>
            <template v-slot:[`item.tripCost`]="{ item }">
              <span class="td-style">₦{{ item.tripCost }}</span>
            </template>
            <template #header.tripTime>
              <span class="status">Time</span>
            </template>
            <template v-slot:[`item.tripTime`]="{ item }">
              <span class="td-style">{{ item.tripTime }}</span>
            </template>
            <template #header.tripStatus>
              <span class="status">Trip Status</span>
            </template>
            <template v-slot:[`item.tripStatus`]="{ item }">
              <v-chip v-if="item.tripStatus === 'completed'" color="#0075CA80">
                <span class="td-style tw-flex" style="color: #163043">
                  <icons name="tick" class="mr-1" />
                  Completed
                </span>
              </v-chip>
              <v-chip v-if="item.tripStatus === 'pending'" color="#AFA25933">
                <span class="td-style" style="color: #8C803B">
                  Pending
                </span>
              </v-chip>
              <v-chip v-if="item.tripStatus === 'on-going'" color="#00C48C69">
                <span class="td-style" style="color: #0F674E">
                  On-going
                </span>
              </v-chip>
              <v-chip v-if="item.tripStatus === 'cancelled'" color="#C8486759">
                <span class="td-style" style="color: #71011C">
                  Cancelled
                </span>
              </v-chip>
            </template>
            <template #header.tripDate>
              <span class="status">Trip Date</span>
            </template>
            <template v-slot:[`item.tripDate`]="{ item }">
              <span class="td-style">{{ item.tripDate }}</span>
            </template>
            <template #header.actions>
              <span class="status">Actions</span>
            </template>
            <template v-slot:[`item.actions`]="{}">
              <v-menu bottom offset-y left>
                <template v-slot:activator="{ on, attrs }">
                  <td class="d-flex justify-end">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon :color="color">mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </td>
                </template>
                <v-list>
                  <v-list-item class="" @click="view('ViewBooking')">
                    <v-list-item-title style="color: black" class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                      <icons name="view-paddler" class="mr-2" />
                      View Booking
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item class="">
                    <v-list-item-title style="color: black" class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                      <icons name="tools" class="mr-2" />
                      Update Booking
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Icons from '../../../components/reuseables/Icons.vue';
  import {
    getAllProducts
  } from '../../../services/api/APIService';
  export default {
    name: "SkipDashboard",
    components: {
      Icons
    },
    data() {
      return {
        color: "#004AAD",
        search: "",
        productName:"ribs",
        items: [{
            text: "All Users",
            value: "users"
          },
          {
            text: "All Bookings",
            value: "bookings"
          },
        ],
        not_found: "Item will show here",
        userHeaders: [{
            text: "Profile",
            value: "profile",
            sortable: false
          },
          {
            text: "Name",
            value: "productUserName",
            sortable: false
          },
          {
            text: "Category",
            value: "category",
            sortable: false
          },
          {
            text: "Status",
            value: "status",
            sortable: false
          },
          {
            text: "Subscription",
            value: "subscription",
            sortable: true
          },
          {
            text: "Verification",
            value: "verified",
            sortable: false
          },
          {
            text: "Start Date",
            value: "startDate",
            sortable: true
          },
          {
            text: "",
            align: "end",
            value: "actions",
            sortable: false
          }
        ],
        bookingHeaders: [{
            text: "Full name",
            value: "traveller",
            sortable: true
          },
          {
            text: "Company",
            value: "tc",
            sortable: false
          },
          {
            text: "Booking ID",
            value: "bookingId",
            sortable: false
          },
          {
            text: "Departure",
            value: "departure",
            sortable: false
          },
          {
            text: "Arrival",
            value: "arrival",
            sortable: false
          },
          {
            text: "Payment ID",
            value: "paymentId",
            sortable: false
          },
          {
            text: "Price",
            value: "tripCost",
            sortable: false
          },
          {
            text: "Time",
            value: "tripTime",
            sortable: false
          },
          {
            text: "Trip status",
            value: "tripStatus",
            sortable: true
          },
          {
            text: "Trip Date",
            value: "tripDate",
            sortable: true
          },
          {
            text: "",
            align: "end",
            value: "actions",
            sortable: false
          }
        ],
        bookingLog: [{
          traveller: "Zoe Davids",
          email: "zoedavids@gmail.com",
          gender: "female",
          phoneNo: "08100000000",
          bookingId: "04773874",
          departure: "Lagos- Jibowu",
          arrival: "Warri-Effurun",
          tripDate: "12-June-2023",
          tripCost: '15000',
          tc: "GUO",
          tripTime: "5:00AM",
          accountNo: "04773874",
          paymentId: "P4773874",
          paymentTime: "5:00AM 12-June-2023",
          tripStatus: "ON-GOING"
        }],
        loading: false,
        selectedFilter: "users",
        productUsers: [],
      };
    },
    methods: {
      getFirstLetter(word) {
        return word.charAt(0)
      },
      async getAllproductUsers() {
        try {
          this.loading = true
          const response = await getAllProducts()
          this.productUsers = response.data.find(product => product.acronym === this.productName )
          this.productUsers = this.productUsers.productUsers
        } catch {
          this.handleError()
        } finally {
          this.loading = false
        }
      },
      handleError(error) {
        this.$displaySnackbar({
          message: error.response.data.details[0] || 'An error occurred',
          success: false
        });
      }
    },
    computed: {
      filteredPaymentLogs() {
        if (this.selectedFilter === "bookings") {
          return this.bookingLog
        } else {
          return this.productUsers
        }
      }
    },
    async created(){
      await this.getAllproductUsers()
    }
  };
</script>

<style lang="scss" scoped>
  .sculptor {
    width: 100%;
  }

  .top-section {
    @media screen and (max-width: 576px) {
      flex-direction: column;
      align-items: start;
    }
  }

  .top-section p {
    color: #263238;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
  }

  .laptop-view {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .mobile-view {
    display: none;

    @media (max-width: 768px) {
      display: flex;
    }
  }

  .add-unit-btn {
    display: flex;
    color: #004aad;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    letter-spacing: 0px;
    padding: 12px;
    border-radius: 10px;
  }

  .add-unit-btn:hover {
    background-color: #c1bfbf4c;
  }

  .total-unit {
    width: 280px;
    height: 140px;
    border-radius: 10px;
    padding: 22px;
    background: #feffff;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.03);

    @media (max-width: 620px) {
      width: 100%;
    }
  }

  .categories-box {
    width: 130px;
    height: 150px;
    padding: 10px 15px;
    flex-shrink: 0;
    border-radius: 10px;
    background: #feffff;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.03);

    @media (max-width: 620px) {
      height: fit-content;
      width: 240px;
    }
  }

  .search-div {
    width: 300px;

    .v-input {
      width: 100%;
    }

    @media screen and (max-width: 1264px) {
      width: 100%;
      margin-top: 15px;
    }
  }

  .search-id-div {
    width: 180px;
    margin-left: 15px;

    .v-input {
      width: 100%;
    }

    @media screen and (max-width: 576px) {
      width: 100%;
      margin-top: 10px;
      margin-left: 0;
    }
  }

  .inner-sculptor {
    flex-direction: column;

    @media (max-width: 620px) {
      flex-direction: row;
    }
  }

  .darker-text,
  .lighter-text {
    color: #263238;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    text-transform: capitalize;
    font-weight: 400;
    line-height: normal;
  }

  ::v-deep .tf-styles input::placeholder {
    color: #959595;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .lighter-text {
    color: #838383;
  }

  .total-unit-figure {
    color: #4f4f4f;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .small-darker-text {
    font-size: 16px;
  }

  .status-tags {
    font-family: Inter;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #BE3321;
    /* Set your desired background color here */
    border-radius: 50%;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: white;

  }

  .v-input {
    width: 200px;
  }

  .company-dashboard {
    display: flex;
    background: #ffffff;
    width: 100%;
    min-height: 70vh;
    max-height: available;
    border-radius: 10px;
    flex-direction: column;
  }

  .card-sculptors {
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 20px;

    @media (max-width: 620px) {
      flex-direction: column;
    }
  }

  .v-chip.v-size--default {
    width: 85px;
    display: flex;
    justify-content: center;
  }

  .header {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #939393;
    letter-spacing: 0px;
    text-transform: capitalize;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: #7cb0f6;
    /* Set your desired background color here */
    border-radius: 50%;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: white;
  }

  .table {
    width: 100%;
  }

  .v-data-table__wrapper>table {
    margin-top: 55px !important;
  }

  .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th:first-child,
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td:first-child {
    padding-left: 16px;
  }

  .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
  .status {
    color: #939393;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
  .td-style {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0;
    text-align: left;
    text-transform: capitalize;
  }

  .company-logo {
    height: 100px;
    width: 100px;
    border-radius: 10px;
    object-fit: contain;
  }

  .no-image {
    display: flex;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-style: normal;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    text-align: center;
  }
</style>